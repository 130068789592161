<template>
    <div>
      <van-nav-bar
          :title="rightName"
          left-text="返回"
          right-text="城市"
          left-arrow
          @click-left="onClickLeft"
          @click-right="onClickRight"
          
          />
          <van-popup v-model="showLocatin" round position="bottom" :style="{ height: '70%' }" >
              <van-picker title="城市" show-toolbar :columns="locationColumns" 
                  @change="onLocationChange" @confirm="onLocationCommit" @cancel="onCancelLocation"/>
          </van-popup>
  
          <div>
  
          <van-pull-refresh v-model="refreshing" @refresh="onRefreshAll">
              <van-list
                  v-model="loading"
                  :finished="finished"
                  finished-text="没有更多了"
                  @load="loadMoreGirls"
              >
  
              <van-cell-group  v-for="girl in allGirls" :key="girl.id" style="margin-bottom: 5px; padding-left: 5px;">
              <van-row>
                  <van-col span="15" @click="handleGirl(girl)">
                      <div style="margin-top: 10px;">{{ girl.title }}</div>
                      <div style="margin-top: 10px;">城市:{{ girl.location }}</div>
                      <div class="van-multi-ellipsis--l3" style="margin-top: 10px;">发布时间:<label>{{ girl.createTime }}</label></div>
                      <div style="margin-top: 10px;">年龄:{{ girl.age }}</div>
                      <div style="margin-top: 10px;">价格:{{ girl.singlePrice }} ~ {{ girl.nightPrice }} </div>
                      <div class="van-multi-ellipsis--l3" style="margin-top: 10px;">{{ girl.details }}</div>
                  </van-col>
                  <van-col span="9" @click="handleGirl(girl)">
                      <van-image
                          width="100%"
                          height="100%"
                          fit="contain"
                          radius="5"
                          :src="girl.pictures[0]"
                          />
                  </van-col>
              </van-row> 
          </van-cell-group>
                  
              </van-list>
              </van-pull-refresh>
          </div>
    </div>
  
  
  </template>
  
  <script>
  import HttpHelper from '@/utils/HttpHelper';
  
  export default  {
      name:'LouFengList',
     
      data(){
          return {
              pId : "",
              cId :"",
              page :1,
              cityName:"",
              proceName:"",
              plainLocation:{},
              locations:{},
              loading:true,
              hasLocation:false,
              showLocatin:false,
              refreshing:false,
              finished:false,
              girls:[],
          }
      },

      props:["turnGirlInfo"],
      computed:{
          allGirls(){
              return this.girls;
          },
          rightName() {
              if (this.pId == "") {
                  return "最新";
              } else {
                  if (this.cId == "") {
                      return this.proceName + "-全部";
                  } else if (this.cId) {
                      return this.proceName + "-" + this.cityName;
                  }
              } 
  
              return "最新";
          },
  
          locationColumns() {
              if (!this.hasLocation) return [];
  
              var pronces = [];
              //var cities = [];
              //var addCity = false
  
              pronces.push("全部");
  
              this.plainLocation.forEach(ele => {
                  
  
                  HttpHelper.lfGetCity(ele.id,(code,citylist)=>{
                        console.log(code);

                        var child = {};

                        child["全部"] = {cityId:0,cityName:"全部"};

                        citylist.forEach(element => {
                            child[element.name] = {cityId:element.id,cityName:element.name, pId:element.pid};
                        });

                        this.locations[ele.name] = {
                            id:ele.id,
                            name:ele.name,
                            child:child,
                        };
                  });
  
                pronces.push(ele.name);
              });
  
              return [{values: pronces}, { values: ["全部"] }];
          }
      },
      mounted(){
          this.refreshLocation();
          this.onRefreshAll();
      },
  
      methods:{
          clearLocation() {
              this.pId = "";
              this.page = 1;
              this.cId = "";
              this.proceName = "";
              this.cityName = "";
          },
  
          onCancelLocation(){
              this.showLocatin = false;
          },
  
          onLocationChange(picker, values){
              var curValue = values[0];
  
              if (curValue == "全部") {
                  picker.setColumnValues(1, ["全部"]);
                  return;
              }
              
              var curPron = this.locations[curValue];
              if (curPron) {
                  var city  = [];
  
                  for (let key in curPron.child) {
                      city.push(curPron.child[key].cityName);
                  }
  
                  picker.setColumnValues(1, city);
              }
          },
  
          onLocationCommit(value, index){
              var pValue = value[0];
              var cValue = value[1];
  
              
              this.showLocatin = false;
  
              if (pValue == "全部" && cValue == "全部") {
                  this.clearLocation();
              } else if (cValue == "全部") {
                  this.cId = "";
                  this.cityName = "";
  
                  var curSel = this.locations[pValue];
                  this.pId = curSel.id;
                  this.proceName = curSel.name;
                  this.page = 1;
              } else {
                  curSel = this.locations[pValue];
                  this.pId = curSel.id;
                  this.proceName = curSel.name;
                  this.cId = curSel.child[cValue].cityId;
                  this.cityName = curSel.child[cValue].cityName;
  
                  this.page = 1;
              }
  
              console.log("onLocationCommit index=",index,",cityName=",this.cityName,",proceName=",this.proceName);
  
              this.finished = false;
  
              this.onRefreshAll()
          },     
  
          refreshLocation(){
              HttpHelper.lfGetLocations((code,datas)=>{
                  if (code != 200) {
                      console.log("query locations fail ", code)
                      return;
                  }
  
                  this.plainLocation = datas;
                  this.hasLocation = true;
              });
          },
  
          refreshAdvert(){
              HttpHelper.lfGetAdverts( (code,datas)=>{
                  if (code != 200) {
                      console.log("query refreshAdvert fail ", code);
                      this.loading = false;
                      return;
                  }
  
                  while(this.girls.length) this.girls.pop();
  
                  datas.forEach(ele => {
                      this.girls.push({
                          id:ele.id,
                          title:ele.title,
                          location:ele.province + "-" + ele.city,
                          age:ele.age,
                          singlePrice:ele.singlePrice,
                          nightPrice:ele.nightPrice,
                          details:ele.details,
                          updateTime:ele.updateTime,
                          createTime:ele.createTime,
                          pictures:ele.pictures,
                      });
                  });
                  
                  this.refreshGirls();
              });
          },
  
          loadMoreGirls(){
              this.page++;
  
              if (this.finished) return;
  
              this.refreshGirls();
          },
  
          refreshGirls(){
              HttpHelper.lfGetGirls(this.page, this.cId, this.pId, (code,datas)=>{
                  if (code != 200) {
                      console.log("query refreshGirls fail ", code);
                      this.loading = false;
                      return;
                  }
  
      
                  //console.log("refreshGirls datas=", datas);
                  if (datas && datas.records) {
                      datas.records.forEach(ele => {
                          this.girls.push({
                              id:ele.id,
                              title:ele.title,
                              location:ele.province + "-" + ele.city,
                              age:ele.age,
                              singlePrice:ele.singlePrice,
                              nightPrice:ele.nightPrice,
                              details:ele.details,
                              updateTime:ele.updateTime,
                              createTime:ele.createTime,
                              pictures:ele.pictures,
                          });
                      });
                  }
                  
                  this.loading = false;
  
                  if (datas.current >= datas.pages) {
                      this.finished = true;
                  }
              });
          },
  
          onRefreshAll(){
              this.refreshAdvert();
          },
  
          onClickLeft(){
              this.$router.back();
          },
  
          onClickRight(){
              this.showLocatin = true;
          },
  
          handleGirl(girl) {
              console.log(girl);
              
              if (this.turnGirlInfo) {
                 this.turnGirlInfo(girl.id);
              }
          },
      }
  }
  
  </script>