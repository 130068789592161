<template>
    <div>
      <van-nav-bar
          :title="girlTitle"
          left-text="返回"
          left-arrow
          @click-left="onClickLeft"
          
          />

          <div>
            <van-swipe :autoplay="3000">
            <van-swipe-item v-for="item in banners" :key="item">
                <van-image  :src="item"   fit="fill"/>
            </van-swipe-item>
        </van-swipe>
          </div>

         <p> {{  girlTitle}}</p>
         <p> 发布时间:{{  publishAt}}</p>
         <p> 年龄:{{  age}}</p>
         <p> 价格:{{  price}}</p>
         <p> 电话:{{  telno}}</p>
         <p> qq:{{  qqno}}</p>
         <p> 微信:{{  wxno}}</p>
         <p> {{  girlServe}}</p>
         <p> {{  girlDetail}}</p>
    </div>
  
  
  </template>
  
  <script>
import HttpHelper from '@/utils/HttpHelper';

 
  export default  {
      name:'LouFengInfo',
     
      data(){
          return {
            girlTitle : "",
            banners:[],
            publishAt:"",
            girlServe:"",
            girlDetail:"",
            age:"",
            price:"",
            telno:"",
            qqno:"",
            wxno:"",
          }
      },

      props:["turnGirls","girlId"],
      
      mounted(){
          this.loadGrilInfo();
      },
  
      methods:{
        loadGrilInfo(){
          HttpHelper.lfGetGirlInfo(this.girlId,(code,data)=>{
            if (code != 200) {
              return;
            }

            this.girlTitle = data.title;
            data.pictures.forEach(img => {
              this.banners.push(img);
            });

            this.publishAt = data.createTime;
            this.age = data.age;
            this.price = data.singlePrice + "~" + data.nightPrice;
            this.telno = data.phone;
            this.qqno = data.qq;
            this.wxno = data.wechat;
            this.girlDetail = data.details;
            this.girlServe = data.serve;
            this.publishAt = data.createTime;

          });
        },
        onClickLeft(){
            if (this.turnGirls) {
              this.turnGirls();
            }
        },
      }
  }
  
  </script>