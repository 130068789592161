

export default class GlobalConfig {
    reported = false;
    banners = [];
    limitReport = false;
    iconAdverts = [];
    hasLouFeng = false;
    louFengData = {};
    
    GlobalConfig(){
        
    }

    static  _sInstance = null;
    static getInstance() {
        if (!GlobalConfig._sInstance) {
            GlobalConfig._sInstance = new GlobalConfig();
        }

        return GlobalConfig._sInstance;
    }
}