import Vue from 'vue'
import App from './App.vue'
import '@vant/touch-emulator'
import VueRouter from 'vue-router'
import VueFocus from 'vue-focus'
import { Button,NavBar,Grid, GridItem,Tabbar, TabbarItem,Swipe, SwipeItem,List,Dialog,Search,Lazyload,Skeleton,PullRefresh,Icon,cell,Popup,Picker} from 'vant'
import { Image as VanImage,Tab,Tabs,Loading } from 'vant';
import { Col, Row ,Tag, CellGroup} from 'vant';
import hls from 'videojs-contrib-hls';
import VideoPlayer from 'vue-video-player';


Vue.use(VueRouter)
Vue.use(VueFocus)
Vue.config.productionTip = false
Vue.use(Button );
Vue.use(VanImage );
Vue.use(NavBar );
Vue.use(List );
Vue.use(Grid );
Vue.use(GridItem );
Vue.use(Tabbar )
Vue.use( TabbarItem )
Vue.use( Search )
Vue.use( Swipe )
Vue.use( SwipeItem )
Vue.use( Lazyload,{lazyComponent:true})
Vue.use( Skeleton )
Vue.use( Dialog )
Vue.use( PullRefresh )
Vue.use( Icon )
Vue.use( cell )
Vue.use(Col);
Vue.use(Loading)
Vue.use(Row);
Vue.use(Tag);
Vue.use(CellGroup);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(hls);
Vue.use(VideoPlayer);

import router from './router'

new Vue({
  render: h => h(App),
  router:router,
}).$mount('#app')
