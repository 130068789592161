<template>
	<div>
        <van-nav-bar :title="title" left-text="返回" left-arrow 
            @click-left="onLeftClick"  
            style="background-color: yellow;"
            >
        
        </van-nav-bar>
        <div class="player-container" v-show="showVideo">
            <video-player class="vjs-custom-skin" :options="playerOptions"  @timeupdate="onPlayerTimeupdate" @play="onVideoPlay"></video-player>
        </div>

        <div><AppList :apps="iconAdverts"></AppList></div>
        <div><VideoList :videoClick="onVideoClick" :videos="recommVideos"></VideoList></div>
        <p>&nbsp;</p>
    </div>
</template>


<script>
require('video.js/dist/video-js.css');
require('vue-video-player/src/custom-theme.css');
import AppList from '@/components/AppList.vue';
import VideoList from './VideoList.vue';
import GlobalConfig from '@/utils/GlobalConfig';
import HttpHelper from '@/utils/HttpHelper';
import Utils from '@/utils/Utils';
import { Dialog } from 'vant';


export default {
    name:"VideoPlay",
    data(){
        return {
            title:"",
            video: {title:"",cover:"",url:"",key:"", id:""},
            recommVideos:[],
            showVideo:false,
            playerOptions: {
                autoplay: false, //如果true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 导致视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                'webkit-playsinline':true,
                playsinline:true,
                'x5-video-player-type':'h5-page',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: "application/x-mpegURL",
                    src: "" //你的m3u8地址（必填）
                }],
                poster: "", //你的封面地址
                width: document.documentElement.clientWidth,
                height:'450px',
                hls: true,
                notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                //  controlBar: {
                //   timeDivider: true,
                //   durationDisplay: true,
                //   remainingTimeDisplay: false,
                //   fullscreenToggle: true //全屏按钮
                //  }
                }
        }
    } ,

    components:{
        AppList,
        VideoList
    },

    props:["handleBack","videoParam"],

    computed:{
        videoData(){
            if (this.videoParam) return this.videoParam;

            return this.$route.query;
        },

        iconAdverts(){
            return GlobalConfig.getInstance().iconAdverts.slice(0,8);
        }
    },
    methods: {
        onLeftClick(){
            if (this.handleBack) {
                this.handleBack()
                return;
            }
            this.$router.back();
        },

        onVideoClick(video) {
            this.fetchVideoDetail(video.key);
        },

        loadVideo (videoInfo) {
            this.playerOptions.sources[0].src = videoInfo.url;
            this.playerOptions.poster = videoInfo.cover;
            this.showVideo = true;

            this.$refs.player;
            // this.dp = new Dplayer({
            //     element: this.$refs.player,
            //     video: {
            //         pic: videoInfo.cover, // 封面
            //         url: videoInfo.url,
            //         type: 'customHls',
            //         customType: {
            //             customHls: function (vd) {
            //                 const cHls = new Hls()
            //                 cHls.loadSource(vd.src)
            //                 cHls.attachMedia(vd)
            //             }
            //         },
            //     },
            //     hotkey:false,
            //     screenshot:false,
            //     autoplay:false,
            //     preload: 'auto',
            //     loop: false,                 
            // });
            
            // this.dp.on("progress", ()=>{
            //     var tick = Math.floor(this.dp.video.currentTime);
            //     var freeSpan = Utils.getFreeSpan();
            //     if (freeSpan > 0 && tick >= freeSpan) {
            //         this.dp.destroy();

            //         Dialog.alert({
            //             message: '试看结束下载客户端畅享全场',
            //         }).then(() => {
            //             window.open(Utils.getNavigator(), "_self")
            //         });
            //     }
            // });
            
            window.scrollTo(0, 0);
        },

        fetchVideoDetail(key) {
            HttpHelper.fetchVideoInfo("", key,(code, data)=>{
                if (code != 200) {
                    console.log("get video info fail ", code)
                    return;
                }

                this.video.id = data.id;
                this.video.cover = data.cover;
                this.video.title = data.title;
                this.video.key = data.key;
                this.video.url = data.url;

                this.title = data.title;

                this.loadVideo(this.video);
                
            });
        },

        fetchRecommVideo(){
            HttpHelper.fetchSimilarVideos("", this.videoData.key, (code, datas) =>{
                if (code != 200) {
                    console.log("get video info fail ", code)
                    return;
                }          
                
                this.recommVideos = datas;
            })
        },

        onVideoPlay(player) {
            var tick = Math.floor(player.currentTime());

            var minSecond = 12;
            if (tick < minSecond) {
                player.currentTime(minSecond);
            }
        },

        onPlayerTimeupdate(player){
            var tick = Math.floor(player.currentTime());
            var freeSpan = Utils.getFreeSpan();
            if (freeSpan > 0 && tick >= freeSpan) {
                player.pause();

                Dialog.alert({
                    message: '试看结束下载客户端畅享全场',
                }).then(() => {
                    window.open(Utils.getNavigator(), "_self")
                });
            }
        }
    },
    mounted () {
        if (!GlobalConfig.getInstance().reported) {
            this.$router.replace("/");
            return;
        }

        this.fetchVideoDetail(this.videoData.key);
        this.fetchRecommVideo();
    },

}
</script>


