import VueRouter from "vue-router";

import HomePage from "@/pages/HomePage.vue";
import PlayHome from "@/pages/PlayHome.vue";
import SearchHome from "@/pages/SearchHome.vue";
import VideoPlay from "@/components/VideoPlay.vue";
import LouFeng from "@/pages/LouFeng.vue";

export default  new VueRouter({
    routes:[
        {
            path:'/', 
            component:HomePage
        },
        {
            path:'/app',
            component:PlayHome,
            
        },
        {
            path:'/play',
            component:VideoPlay
            
        },
        {
            path:'/search',
            component:SearchHome,
        },
        {
            path:'/loufeng',
            component:LouFeng,
        }
    ]
});