<template>
  <div>
    <keep-alive include="LouFengList">
        <component v-bind:is="currentTabComponent" :turnGirls="onTurnGirls" :turnGirlInfo="onTurnGirlInfo" :girlId="girlId"></component>
    </keep-alive>
  </div>


</template>

<script>
import LouFengInfo from '@/components/LouFengInfo.vue';
import LouFengList from '@/components/LouFengList.vue';



export default  {
    name:'LouFeng',
    components:{LouFengList, LouFengInfo},
    data(){
        return {
            allComponets:["LouFengList","LouFengInfo"],
            showIndex:0,
            girlId:"",
        }
    },
    computed:{
        currentTabComponent(){
            return this.allComponets[this.showIndex];
        },
    },
    

    methods:{
        onTurnGirlInfo(id) {
           this.girlId = id;
           this.showIndex = 1;
       },

       onTurnGirls(){
           this.showIndex = 0;
       }
    }
}

</script>