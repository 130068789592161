<template>
    <div>
        <van-skeleton title avatar :row="3" :loading="loading">
            <TopHeader></TopHeader>
            <TopSearch></TopSearch>
            <TopBanner :banners="banners"></TopBanner>
            <AppList :colNum="4" :apps="apps" :fetchList="refreshAdvertList"></AppList>
        </van-skeleton>
        <br/>
        <p>&nbsp;</p>
    </div>
    </template>
    
    <script>
    import TopHeader from '@/components/TopHeader.vue'
    import TopSearch from '@/components/TopSearch.vue'
    import TopBanner   from '@/components/TopBanner.vue';
    import AppList from '@/components/AppList.vue';
    import HttpHelper from '@/utils/HttpHelper';
    import GlobalConfig from '@/utils/GlobalConfig';

    export default {
        name:"HomeNavigator",
        data() {
            return {
                loading:true,
                apps:[],
                banners:[],
            }
        },
    
        components:{
            TopSearch,
            TopHeader,
            TopBanner,
            AppList
        },
    
        methods:{
            finishLoad() {
                this.loading = false;
            },
    
            refreshBanner(){
                HttpHelper.queryBannerList("", (code, data) =>{
                    if (code != 200) {
                        console.log("query banner fail ", code)
                        return;
                    }

                    while(this.banners.shift());

                    data.forEach(ele => {
                        this.banners.push(ele);
                    });
                });
            },


        refreshAdvertList(cb) {
            HttpHelper.queryAdvertList("", (code, data) =>{
                if (code != 200) {
                    console.log("query icon fail ", code)
                    return;
                }

                if (data && data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        data[i].kind = 1
                        data[i].key = data[i].href;
                    }
                }

                while(this.apps.shift());

                data.forEach((ele)=>{
                    this.apps.push(ele);
                })
                
                this.finishLoad();

                while(GlobalConfig.getInstance().iconAdverts.shift());

                data.forEach((ele)=>{
                    GlobalConfig.getInstance().iconAdverts.push(ele);
                })

                if (cb)cb();
            });  
        }
        },
    
        mounted() {
            this.refreshAdvertList();
            this.refreshBanner();
        }
    }
    </script>